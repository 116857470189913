import { computed, effect, Injectable, signal, untracked } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { OptionValue } from '../../framework/services/options.service';
import { StateService } from '../../framework/services/state.service';
import { LocalPersistentStorageService } from './local-persistent-storage.service';
import { FlexibleLabel, LabelKey, LocalizedLabel, PlainLabel } from '../../../../common/models/Label';
import { map, Observable } from 'rxjs';
import { isNotNil } from '../../framework/services/helper.service';

const stringParameterOrNull = (value: unknown): string | null => {
    if (typeof value !== 'string') {
        return null;
    }
    if (value.length === 0) {
        return null;
    }

    return value;
};

@Injectable({
    providedIn: 'root',
})
export class LocaleService {
    localeOptions = signal<OptionValue[]>([
        { id: 'fi', label: 'Suomi' },
        { id: 'en', label: 'English' },
    ]).asReadonly();

    userLocale = computed(() => this.stateService.context().locale ?? null);

    localLocale = signal(this.storageService.get('lang') ?? 'fi');

    locale = computed(() => this.userLocale() ?? this.localLocale() ?? 'fi');

    locale$ = toObservable(this.locale);

    constructor(
        private stateService: StateService,
        private storageService: LocalPersistentStorageService,
        private translateService: TranslateService,
    ) {
        effect(() => {
            const locale = this.locale();
            untracked(() => {
                this.storageService.set('lang', locale);
            });
        });
        effect(
            () => {
                const userLocale = this.userLocale();

                untracked(() => {
                    this.localLocale.update((current) => userLocale ?? current);
                });
            },
            { allowSignalWrites: true },
        );
        effect(() => {
            const locale = this.locale();

            untracked(() => {
                this.translateService.use(locale);
            });
        });
    }

    narrowLabel$<T extends FlexibleLabel>(label: T): Observable<T & PlainLabel> {
        return this.locale$.pipe(map((it) => this.narrowLabel(label, it)));
    }

    narrowLabel<T extends FlexibleLabel>(label: T, locale: string): T & PlainLabel {
        if (this.isLocalizedLabel(label)) {
            return {
                ...label,
                label: locale === 'fi' ? label.labelFi : label.labelEn,
            };
        }

        if (this.isLabelKey(label)) {
            return {
                ...label,
                label: this.translateService.instant(label.labelKey, label.interpolateParams),
            };
        }
        if (this.isPlainLabel(label)) {
            return label;
        }

        return {
            ...label,
            label: '-',
        };
    }

    isLocalizedLabel(it: unknown): it is LocalizedLabel {
        return (
            isNotNil(stringParameterOrNull((it as Record<string, string>)?.['labelEn'])) &&
            isNotNil(stringParameterOrNull((it as Record<string, string>)?.['labelFi']))
        );
    }

    isLabelKey(it: unknown): it is LabelKey {
        return isNotNil(stringParameterOrNull((it as Record<string, string>)?.['labelKey']));
    }

    isPlainLabel(it: unknown): it is PlainLabel {
        return isNotNil(stringParameterOrNull((it as Record<string, string>)?.['label']));
    }
}
