import { Injectable } from '@angular/core';
import { isNil, isNotNil, parseOrNull } from '../../framework/services/helper.service';

/**
 *  A service to use localStorage. Thought it's a good idea to have a service for it and not use localStorage directly everywhere.
 */
@Injectable({
    providedIn: 'root',
})
export class LocalPersistentStorageService {
    set(key: string, value: string | null) {
        if (isNil(value)) {
            this.remove(key);
        } else {
            localStorage.setItem(key, value);
        }
    }

    setJSON<T>(key: string, value: T | null) {
        this.set(key, isNotNil(value) ? JSON.stringify(value, undefined, 4) : null);
    }

    get(key: string): string | null {
        return localStorage.getItem(key) ?? null;
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }

    getJSONOrNull<T>(key: string): T | null {
        const value = this.get(key);
        return isNotNil(value) ? parseOrNull(value) : null;
    }

    clear() {
        localStorage.clear();
    }
}
