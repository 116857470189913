import { HttpContextToken, HttpEvent, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs';
import { isNotNil } from '../../framework/services/helper.service';

export const SKIP_DATE_INJECTION = new HttpContextToken(() => false);

const iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

function isObject(value: unknown): value is Record<string, unknown> {
    return isNotNil(value) && typeof value === 'object';
}

function isIso8601(value: unknown): value is string {
    return typeof value !== 'string' ? false : iso8601.test(value);
}

function convertToDate(body: unknown) {
    if (!isObject(body)) {
        return body;
    }

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIso8601(value)) {
            body[key] = new Date(value);
        } else if (typeof value === 'object') {
            body[key] = convertToDate(value);
        }
    }
    return body;
}

/**
 * Converts DateIsoStrings into Date Object.
 * @param request
 * @param next
 * @constructor
 */
export const DateInterceptor: HttpInterceptorFn = (request, next) => {
    const skip = request.context.get(SKIP_DATE_INJECTION);
    if (skip) {
        return next(request);
    }

    if (request.method === 'JSONP') {
        return next(request);
    }

    return next(request).pipe(
        tap({
            next: (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const body = event.body;
                    convertToDate(body);
                }
            },
        }),
    );
};
